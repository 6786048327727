<script>
export default {
  props: {
    value: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      src: null,
      loading: false,
    };
  },
  methods: {
    loadImage(src) {
      return new Promise((resolve, reject) => {
        var image = new Image();
        image.onload = () => resolve(image);
        image.onerror = () => reject(new Error("could not load image"));
        image.src = src;
      });
    },
    setup() {
      this.loading = true;
      this.loadImage(this.value)
        .then(() => {
          this.src = this.value;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.setup();
  },
  watch: {
    value() {
      this.setup();
    },
  },
};
</script>

<template>
  <div class="profileCver">
    <app-loading v-if="loading" :active="true" />
    <img v-if="src && !loading" :src="src" />
    <svg class="size-holder" viewBox="0 0 5 1" v-else />
  </div>
</template>
